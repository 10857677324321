import React from 'react';
import { Metadata } from '../components';
import StandardLayout from '../layouts/standard';


const NotFoundPage = () => (
	<StandardLayout>
		<Metadata title="404: Not found" />
		<h1>NOT FOUND</h1>
		<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
	</StandardLayout>
);

export default NotFoundPage;
